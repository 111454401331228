import { memo } from "react";
import IconCb from "app/pages/.shared/static/icons/IconCb";
import IconMastercard from "app/pages/.shared/static/icons/IconMastercard";
import IconVisa from "app/pages/.shared/static/icons/IconVisa";
import IconAmex from "app/pages/.shared/static/icons/IconAmex";
import IconPaypal from "app/pages/.shared/static/icons/IconPaypal";
import GooglePayIcon from "app/pages/.shared/static/icons/GooglePayIcon";
import ApplePayIcon from "app/pages/.shared/static/icons/ApplePayIcon";
import "./ReassurancePayment.scss";
import PropTypes from "prop-types";
import { CREDIT_CARDS_TYPES, PAYMENT_METHODS } from "app/constants";

const iconByPaymentMethod = {
	[CREDIT_CARDS_TYPES.CB]: IconCb,
	[CREDIT_CARDS_TYPES.MASTERCARD]: IconMastercard,
	[CREDIT_CARDS_TYPES.VISA]: IconVisa,
	[CREDIT_CARDS_TYPES.AMEX]: IconAmex,

	[CREDIT_CARDS_TYPES.CARD]: () => (
		<>
			<IconCb width={32} height={32} />
			<IconMastercard width={32} height={32} />
			<IconVisa width={32} height={32} />
			<IconAmex width={32} height={32} />
		</>
	),
	[CREDIT_CARDS_TYPES.GOOGLE_PAY]: GooglePayIcon,
	[CREDIT_CARDS_TYPES.APPLE_PAY]: ApplePayIcon,
};

// eslint-disable-next-line react/no-multi-comp
const ReassurancePayment = ({ paymentMethods = [] }) => {
	const uniquePaymentMethods = [
		...new Set(
			paymentMethods?.flatMap(
				paymentMethod => paymentMethod.constraints.acceptedCreditCardTypes
			)
		),
	];

	const cardPaymentMethods = uniquePaymentMethods.filter(
		paymentMethod =>
			paymentMethod !== CREDIT_CARDS_TYPES.APPLE_PAY &&
			paymentMethod !== CREDIT_CARDS_TYPES.GOOGLE_PAY
	);

	const mobilePaymentMethods = uniquePaymentMethods.filter(
		paymentMethod =>
			paymentMethod === CREDIT_CARDS_TYPES.APPLE_PAY ||
			paymentMethod === CREDIT_CARDS_TYPES.GOOGLE_PAY
	);

	const hasPaypal = paymentMethods?.some(
		paymentMethod => paymentMethod.code === PAYMENT_METHODS.PAYPAL
	);

	return (
		<div className="reassurance-payment">
			<div className="reassurance-payment__card-payment">
				{cardPaymentMethods.map(card => {
					const CardPaymentIcon = iconByPaymentMethod[card];

					return CardPaymentIcon && <CardPaymentIcon key={card} width={32} height={32} />;
				})}

				{hasPaypal && <IconPaypal width={32} height={32} />}
			</div>

			{mobilePaymentMethods.length > 0 && (
				<div className="reassurance-payment__mobile-payment">
					{mobilePaymentMethods.map(mobile => {
						const MobilePaymentIcon = iconByPaymentMethod[mobile];

						return MobilePaymentIcon && <MobilePaymentIcon key={mobile} height={24} />;
					})}
				</div>
			)}
		</div>
	);
};

ReassurancePayment.propTypes = {
	paymentMethods: PropTypes.array,
};

export default memo(ReassurancePayment);
